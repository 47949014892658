




























































import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserStore from "@/store/modules/UserStore";

interface NavbarItemInfo {
  name: string;
  icon: string;
  text: string;
  authNeeded: boolean;
  adminNeeded: boolean;
  studentNeeded?: boolean;
}

@Component
export default class Header extends Vue {
  userStore = getModule(UserStore);
  routerInfo: Array<NavbarItemInfo> = [
    {
      name: "Home",
      icon: "home",
      text: "Startseite",
      authNeeded: false,
      adminNeeded: false,
    },
    {
      name: "Subjects",
      icon: "edit",
      text: "Meine Wahl",
      authNeeded: true,
      adminNeeded: false,
      studentNeeded: true,
    },
    {
      name: "Admin",
      icon: "user",
      text: "Admin",
      authNeeded: true,
      adminNeeded: true,
    },
    {
      name: "Statistiken",
      icon: "chart-pie",
      text: "Statistiken",
      authNeeded: true, // until implemented
      adminNeeded: true,
    },
    {
      name: "Settings",
      icon: "cogs",
      text: "Einstellungen",
      authNeeded: true,
      adminNeeded: false,
      studentNeeded: true,
    },
  ];

  get getRouterLinks(): Array<NavbarItemInfo> {
    const allowedRoutes = new Array<NavbarItemInfo>();
    if (this.userStore.isAuthenticated) {
      this.setRoutesIfAuthenticated(allowedRoutes);
    } else {
      this.routerInfo.forEach((link) => {
        if (!link.authNeeded) {
          allowedRoutes.push(link);
        }
      });
    }

    return allowedRoutes;
  }

  setRoutesIfAuthenticated(allowedRoutes: Array<NavbarItemInfo>): void {
    this.routerInfo.forEach((link) => {
      if (link.adminNeeded && this.userStore.isUserAdmin) {
        allowedRoutes.push(link);
      } else if (link.studentNeeded && this.userStore.isStudent) {
        allowedRoutes.push(link);
      } else if (!link.adminNeeded && !link.studentNeeded) {
        allowedRoutes.push(link);
      }
    });
  }

  logout(): void {
    this.userStore.removeUserData();
    this.$router.push({ name: "Logout" });
  }
}
